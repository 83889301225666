import React, { useState, useEffect } from "react";
import UserNav from "../user-navbar/user-navbar";
import { FiSun } from "react-icons/fi";
import moment from "moment";
import { HiUsers } from "react-icons/hi";
import { TfiStatsUp } from "react-icons/tfi";
import { IoMdTimer } from "react-icons/io";
import AttendanceOverview from "./attendance_table";
import LeaveRequestUser from "./leave_request_user";

const Dashboard = () => {
  const currentDate = moment().format("MMMM Do YYYY");
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [leaveCounts, setLeaveCounts] = useState({
    annual: { current: 0, actual: 0 },
    casual: { current: 0, actual: 0 },
    medical: { current: 0, actual: 0 },
    special: { current: 0, actual: 0 },
    noPay: { current: 0, actual: 0 },
  });
  const [employeeNo, setEmployeeNo] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    if (employeeNo) {
      // Fetch leave data with employee_no
      fetch(
        `https://backbritish.talentfort.live/v1/hris/leave/getLeaveCounts?employee_no=${employeeNo}`
      )
        .then((response) => response.json())
        .then((data) => {
          setLeaveCounts({
            annual: {
              current: parseFloat(data.currentLeave.annualLeaveCount) || 0,
              actual: parseFloat(data.actualLeave.annualLeaveCount) || 0,
            },
            casual: {
              current: parseFloat(data.currentLeave.casualLeaveCount) || 0,
              actual: parseFloat(data.actualLeave.casualLeaveCount) || 0,
            },
            medical: {
              current: parseFloat(data.currentLeave.medicalLeaveCount) || 0,
              actual: parseFloat(data.actualLeave.medicalLeaveCount) || 0,
            },
            special: {
              current: parseFloat(data.currentLeave.specialLeaveCount) || 0,
              actual: parseFloat(data.actualLeave.specialLeaveCount) || 0,
            },
            noPay: {
              current: parseFloat(data.currentLeave.noPayLeaveCount) || 0,
              actual: 0, // No actual value for noPay in the response
            },
          });
          
        })
        .catch((error) => {
          console.error("Error fetching leave data:", error);
        });
    }
  }, [employeeNo]);

  useEffect(() => {
    // Retrieve employee_no from cookie
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const employeeNoFromCookie = getCookie("employee_no");
    if (employeeNoFromCookie) {
      setEmployeeNo(employeeNoFromCookie);
    }
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-[#F1F2F6] overflow-y-auto h-screen">
      <div>
        <UserNav />
      </div>

      <div className="flex gap-10 mt-10 mx-[5%] justify-center">
        {/* Time and date card */}
        <div>
          <div className="bg-white p-6 py-[100px] rounded-lg h-[540px] w-[400px]">
            <div className="flex items-center gap-5 px-10">
              <div>
                <FiSun className="w-[80px] h-[80px] opacity-45" />
              </div>
              <div className="font-bold text-[28px] text-[#3D0B5E]">
                {currentTime}
                <p className="text-lg font-normal">Real Time Insight</p>
              </div>
            </div>

            <div className="mt-[50px] font-bold px-10">
              <p className="font-bold text-[#3D0B5E] text-[25px]">Today :</p>
              <div className="text-[#3D0B5E] text-[20px]">{currentDate}</div>
            </div>

            <div className="mt-5 px-10">
              <button
                onClick={handleOpenModal}
                className="bg-[#6A2E92] text-white p-2 rounded-lg text-[20px]"
              >
                New Leave Application
              </button>
            </div>
          </div>
        </div>

        {/* Other cards */}
        <div className="grid grid-cols-3 grid-flow-row gap-8">
          {/* Card 1 */}
          <div className="bg-white p-5 rounded-lg w-[320px] h-[250px]">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[48px] text-[#3D0B5E]">
                
                  {leaveCounts.medical.current.toFixed(1)}/ {leaveCounts.medical.actual.toFixed(1)} 
                </p>
              </div>
              <div className="bg-[#E6EAF5] rounded-full p-3">
                <HiUsers className="h-[20px] w-[18px]" />
              </div>
            </div>

            <div>
              <p className="text-[25px]">Medical Leaves</p>

              <div className="flex items-center gap-3 mt-5">
                <div className="bg-[#A07AF0] rounded-full p-2"></div>
                <p className="text-lg">
                  Remaining Count: {leaveCounts.medical.current.toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="bg-white p-5 rounded-lg w-[320px] h-[250px]">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[48px] text-[#3D0B5E]">
                 
                  {leaveCounts.annual.current.toFixed(1)}/ {leaveCounts.annual.actual.toFixed(1)} 
                </p>
              </div>
              <div className="bg-[#E6EAF5] rounded-full p-3">
                <TfiStatsUp />
              </div>
            </div>

            <div>
              <p className="text-[25px]">Annual Leaves</p>

              <div className="flex items-center gap-3 mt-5">
                <div className="bg-[#F2E56F] rounded-full p-2"></div>
                <p className="text-lg">
                  Remaining Count: {leaveCounts.annual.current.toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="bg-white p-5 rounded-lg w-[320px] h-[250px]">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[48px] text-[#3D0B5E]">
                  {leaveCounts.casual.current.toFixed(1)}/  {leaveCounts.casual.actual.toFixed(1)}
                </p>
              </div>
              <div className="bg-[#E6EAF5] rounded-full p-3">
                <IoMdTimer />
              </div>
            </div>

            <div>
              <p className="text-[25px]">Casual Leaves</p>

              <div className="flex items-center gap-3 mt-5">
                <div className="bg-[#FA0BA9] rounded-full p-2"></div>
                <p className="text-lg">
                  Remaining Count: {leaveCounts.casual.current.toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          {/* Card 4 */}
          <div className="bg-white p-5 rounded-lg w-[320px] h-[250px]">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[48px] text-[#3D0B5E]">
                  {leaveCounts.noPay.current.toFixed(1)}
                </p>
              </div>
              <div className="bg-[#E6EAF5] rounded-full p-3">
                <HiUsers className="h-[20px] w-[18px]" />
              </div>
            </div>

            <div>
              <p className="text-[25px]">No Pay Leaves</p>

              <div className="flex items-center gap-3 mt-5">
                <div className="bg-[#E6EAF5] rounded-full p-2"></div>
                <p className="text-lg">
                  Remaining Count: {leaveCounts.noPay.current.toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          {/* Card 5 */}
          <div className="bg-white p-5 rounded-lg w-[320px] h-[250px]">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[48px] text-[#3D0B5E]">
                  
                  {leaveCounts.special.current.toFixed(1)}/{leaveCounts.special.actual.toFixed(1)} 
                </p>
              </div>
              <div className="bg-[#E6EAF5] rounded-full p-3">
                <IoMdTimer />
              </div>
            </div>

            <div>
              <p className="text-[25px]">Special Leaves</p>

              <div className="flex items-center gap-3 mt-5">
                <div className="bg-[#FA0BA9] rounded-full p-2"></div>
                <p className="text-lg">
                  Remaining Count: {leaveCounts.special.current.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Attendance Overview */}
      <div className="mt-[60px] mx-[5%]">
        <AttendanceOverview />
      </div>

      {/* Leave Request Modal */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-400 bg-opacity-55 z-50">
          <div className="bg-white rounded-lg p-8">
            {/* Pass leaveCounts as a prop */}
            <LeaveRequestUser
              onClose={handleCloseModal}
              leaveCounts={leaveCounts}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
